import axios from 'axios';
import React, { ReactNode, useEffect, useState } from 'react';
import { Navigate } from 'react-router';

const AuthGuard : React.FC<{component:ReactNode}> = ({component}) => {
  const tempToken = localStorage.getItem("token");

      if(!tempToken) return <Navigate to="/login" replace/>
    return <React.Fragment>{component}</React.Fragment>;
}

export default AuthGuard;