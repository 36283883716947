import {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { addHistoryAction, toggleChatAction } from "../reducers/chatActions";
import { chatReducer } from "../reducers/chatReducer";
import { addMessageAction } from "../reducers/chatActions";
import { addConversationsAction } from "../reducers/conversationActions";
import { conversationReducer } from "../reducers/conversationReducer";
import { userReducer } from "../reducers/userReducer";
import { ws } from "../ws";
import { addUsersAction } from "../reducers/userActions";
import { UserContext } from "./UserContext";
// import collect from "collect.js";
import socketIOClient, { Socket } from "socket.io-client";
import axios from "axios";
import { ChatsValue, IMessage } from "../model/chat";
import { axiosNode } from "../helper/axiosInstance";
import { toast } from "react-toastify";

interface Props {
  children: React.ReactNode;
}

export const ChatContext = createContext<ChatsValue>({
  chats: {
    messages: [],
    isChatOpen: false,
  },
  sendMessage: (message: string, groupId: number, attachment: object) => {},
  sendFile: (
    message: string,
    roomId: number,
    author: string,
    conversation_id: number,
    attachment: object
  ) => {},
  sendFileCamera: (
    message: string,
    roomId: number,
    author: string,
    conversation_id: number,
    attachment: object
  ) => {},
  sendFileCameraVideo: (
    message: string,
    roomId: number,
    author: string,
    conversation_id: number,
    attachment: object
  ) => {},
  toggleChat: () => {},
  groups: {
    groups: [],
  },
  users: {
    users: [],
  },
  roomStatus: false,
  joinRoom: (roomId: string) => {},
  targetUserId: "",
  conversationId: {},
  newChat: false,
  newConversation: (user: any, senderUser: number) => {},
  disableChat: true,
  broadcast: {},
  fetchGroups: () => {},
  joinedRoomId:"",
  setJoinedRoomId:(id:string)=>{},
});

export const ChatProvider: React.FC<Props> = ({ children }) => {
  const [joinedRoomId,setJoinedRoomId] = useState(localStorage.getItem("joined_room_id")
    ? localStorage.getItem("joined_room_id")
    : null);
  const [chats, chatDispatch] = useReducer(chatReducer, {
    messages: [],
    isChatOpen: false,
  });
  const [targetUserId, setTargetUserId] = useState<string>("");
  const [conversationId, setConversationId] = useState<any>({});
  const [groups, groupDispatch] = useReducer(conversationReducer, {
    groups: [],
  });
  const [users, usersDispatch] = useReducer(userReducer, { users: [] });
  const [roomStatus, setRoomStatus] = useState<boolean>(false);
  const [newChat, setNewChat] = useState<boolean>(false);
  const { user } = useContext(UserContext);
  const [disableChat, setDisableChat] = useState<boolean>(true);
  const [broadcast, setBroadcast] = useState<any>({});
  const [roomId, setRoomId] = useState(-1);

  const fetchGroups = async () => {
    // coversationDispatch(addConversationsAction(conversation.resources));

    await axiosNode.get("/api/group-chats/signed")
      .then(async function (res) {
        await groupDispatch(addConversationsAction(res.data));
        // setGrouplists(res.data);
      });
  };

  const sendMessage = async (
    message: string,
    groupId: number,
    attachment: any
  ) => {
    if (message && message.trim().length > 0) {

      const body = {
        message,
        groupId: Number(groupId),
      };

      axiosNode.post("/api/chats/send/",body)
        .then(async function (res) {
          const ChatContainer = document.getElementById("chat-container");
          setTimeout(() => {
            ChatContainer?.scrollTo({
              top: ChatContainer?.scrollHeight,
              behavior: "smooth",
            });
          }, 100);
        });
    }
  };

  const sendFileCamera = (
    message: string,
    groupId: number,
    attachment: any
  ) => {
    if (attachment) {
      sendMessage(message, groupId, {
        name: attachment.name,
        data: attachment.data,
      });
    }
  };

  const sendFileCameraVideo = (
    message: string,
    groupId: number,
    attachment: any
  ) => {
    if (attachment) {
      const reader = new FileReader();
      reader.readAsArrayBuffer(attachment.data);
      reader.onload = () => {
        sendMessage(message, groupId, {
          name: attachment.name,
          data: reader.result,
        });
      };
    }
  };

  const sendFile = (message: string, groupId: number, attachment: any) => {
    if (attachment) {
      // if (attachment.target.files[0].size <= 512000){
      if (attachment.target.files[0].size <= 10485760) {
        // if (attachment.target.files[0].type === 'video/mp4') {
        const reader = new FileReader();
        reader.readAsArrayBuffer(attachment.target.files[0]);
        reader.onload = () => {
          sendMessage(message, groupId, {
            name: attachment.target.files[0].name,
            data: reader.result,
          });
        };
        // } else {
        // const reader = new FileReader();
        // reader.readAsDataURL(attachment.target.files[0]);
        // reader.onload = () => {
        //     sendMessage(message, roomId, author, conversation_id, {
        //         name: attachment.target.files[0].name,
        //         data: reader.result
        //     })
        // }
        // }
      } else {
        alert("File is too large for upload.");
      }
    }
  };

  const addMessage = (message: IMessage) => {
    const ChatContainer1 = document.getElementById("chat-container");
    setTimeout(() => {
      ChatContainer1?.scrollTo({
        top: ChatContainer1?.scrollHeight,
        behavior: "smooth",
      });
    }, 300);
    chatDispatch(addMessageAction(message));
  };

  const addHistory = (messages: IMessage[]) => {
    const ChatContainer2 = document.getElementById("chat-container");
    chatDispatch(addHistoryAction(messages));
    setTimeout(() => {
      ChatContainer2?.scrollTo({
        top: ChatContainer2?.scrollHeight,
      });
    }, 1);
  };

  const handleReceiveMessage = (msg: any) => {
    const joinedRoomId = localStorage.getItem("joined_room_id")
      ? localStorage.getItem("joined_room_id")
      : null;
    if (msg.groupId === Number(joinedRoomId)) {
      const ChatContainer1 = document.getElementById("chat-container");
      setTimeout(() => {
        ChatContainer1?.scrollTo({
          top: ChatContainer1?.scrollHeight,
          behavior: "smooth",
        });
      }, 300);
      const formattedMessage: IMessage = {
        attachment: null,
        created_at: msg.createdAt,
        message: msg.message,
        sender_id: msg.senderId,
        sender_name: msg.senderName,
      };
      chatDispatch(addMessageAction(formattedMessage));
    }
    fetchGroups();
    // const tempRoomList = conversations.conversations.map((conv : any)=>{
    //   let temp = {...conv};
    //   if(conv.id === Number(joinedRoomId) && (conv.latest_message.message !== formattedMessage.message || conv.latest_message.sender_name !== formattedMessage.sender_name)){
    //     temp.latest_message.message = formattedMessage.message;
    //     temp.latest_message.sender_name = formattedMessage.sender_name;
    //     return temp;
    //   }
    //   return conv;
    // })
    // coversationDispatch(addConversationsAction(tempRoomList as []));
  };

  const toggleChat = () => {
    chatDispatch(toggleChatAction(!chats.isChatOpen));
  };

  const joinRoom = async (roomId: string) => {

    axiosNode.get("/api/group-chats/select/"+roomId)
      .then(async function (res) {
        addHistory(res.data);
        //   chatDispatch(addMessageAction(res.data))
        //   chats.messages = res.data
        localStorage.setItem("joined_room_id", roomId);
        setJoinedRoomId(roomId);
        setRoomId(Number(roomId));

        setConversationId(roomId);
      }).catch((e)=>{
        localStorage.removeItem("joined_room_id");
        setJoinedRoomId("");
      });
  };

  const fetchUsers = (users: any) => {
    usersDispatch(addUsersAction(users.resources));
  };

  const newConversation = (user: any, senderUser: number) => {
    setNewChat(!newChat);
    // const checkConversation = collect(conversations).where()
    ws.emit("create-conversation", {
      participant: user.id,
      user_id: senderUser,
    });
  };
  const handleAddedToGroup = async (name : string,ws:Socket) =>{
      await fetchGroups();
      ws.emit("refresh-socket");
      toast(`You have been invited to join ${name}`, {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        type: "info",
      });
    
  }

  const handleRemovedFromGroup = async (groupId : number,ws:Socket) =>{
    const currRoom = Number(localStorage.getItem("joined_room_id"))
    if(currRoom === groupId){
      localStorage.removeItem("joined_room_id");
      setJoinedRoomId("");
    }
    ws.emit("refresh-socket");
    toast(`You have been removed from group`, {
      position: "top-right",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      type: "warning",
    });
  }

  const handleChangeGroupStatus = async (groupId : number,status:string,ws:Socket) =>{
    await fetchGroups();
    if(status === 'deleted'){
      handleRemovedFromGroup(groupId,ws)
    }else{
      ws.emit("refresh-socket");
      toast(`Group status has been changed to ${status}`, {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        type: "info",
      });
    }

  }

  useEffect(() => {
    // startWS(user.id)
    let ws = socketIOClient(process.env.REACT_APP_BACKEND_API as string, {
      extraHeaders: { id: user.id },
    });
    ws.on("receive_message", handleReceiveMessage);
    ws.on("new-group",(data)=>handleAddedToGroup(data.group_name,ws));
    ws.on("group-status-change",(data)=>handleChangeGroupStatus(data.groupId,data.status,ws));
    return () => {
      ws.off("receive_message");
      ws.off("new-group");
      ws.off("group-status-change");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <ChatContext.Provider
      value={{
        sendFileCameraVideo,
        sendFile,
        sendFileCamera,
        toggleChat,
        sendMessage,
        groups,
        chats,
        roomStatus,
        joinRoom,
        targetUserId,
        conversationId,
        newChat,
        users,
        newConversation,
        disableChat,
        broadcast,
        fetchGroups,
        joinedRoomId,
        setJoinedRoomId,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};
