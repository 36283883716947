import { ErrorMessage } from "@hookform/error-message";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link } from "@mui/material";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { Link as RouterLink, useNavigate, useParams } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import mathMavensLogo from "../assets/images/math-mavens-logo.png";

type LoginType = {
    email: string;
  };

const ForgetPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSent,setIsSent] = useState(false);
  const navigate = useNavigate();
  const schema = yup.object().shape({
    email: yup.string().email().required(),
  });

  const {
    handleSubmit: tes,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleSubmit = async (data: LoginType) => {
    setIsLoading(true);
    const url = (process.env.REACT_APP_AUTH_API + "/api/auth/reset-password/request") as string;
    // e.preventDefault();
    axios
      .post(url, {
        email: data.email,
      })
      .then(async (res) => {
        setIsSent(true);
        toast("Reset Password link has been succesfully sent to your email", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            type: "success",
          });
        // return navigate("/");
      })
      .catch((err) => {
        console.log(err);
        toast(err.response.data.message || "Something went wrong", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          type: "error",
        });
        console.log(err.message);
        //  alert(err)
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="App section-node">
        <ToastContainer />
      {!isSent && <section className="w-full">
        <div className="py-[16rem] flex-col justify-center items-center 2xl:py-[32rem]">
          <div className="flex justify-center items-baseline my-4">
            <img
                  className="w-12 h-full"
                  src={mathMavensLogo}
                  alt="Math_Mavens_logo"
                />
            <h1 className="ml-2 text-primary text-center not-italic font-bold leading-10 text-xl">
              Chat App
            </h1>
          </div>
          <form onSubmit={tes(handleSubmit)}>
            <div className="mb-4">
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <>
                    <input
                      placeholder="Email"
                      type="text"
                      {...field}
                      className="border h-12 w-81 rounded-full outline-none px-4 shadow-main text-sm"
                    />
                    <ErrorMessage
                      errors={errors}
                      name="email"
                      render={({ message }) => (
                        <p className="text-red-500 text-xs">{message}</p>
                      )}
                    />
                  </>
                )}
              />
            </div>

            <div className="mb-4 mx-auto max-w-[344px]">
              <Link component={RouterLink} to="/login">
                Remember Password?
              </Link>
            </div>
            <div className="action mb-4">
              <button
                disabled={isLoading}
                type="submit"
                className="shadow-main button-primary rounded-full h-12 w-81 shadow-md ml-2"
              >
                {isLoading ? "Loading..." : "Confirm"}
              </button>
            </div>
          </form>
        </div>
      </section>}
      {isSent && <section className="w-full">
        <div className="py-[16rem] flex-col justify-center items-center 2xl:py-[32rem]">
          <div className="flex justify-center items-baseline my-4">
            <img
                  className="w-12 h-full"
                  src={mathMavensLogo}
                  alt="Math_Mavens_logo"
                />
          </div>
         <h1>Please Check your email for your reset link.</h1>
        </div>
      </section>}
    </div>
  );
};

export default ForgetPassword;
