import axios from 'axios'


const handleRefreshToken = async() =>{
  const token = await localStorage.getItem('refreshToken');
  const url = process.env.REACT_APP_BACKEND_API + '/api/auth/refresh' as string
  const tokenData:string = `Bearer ${token}` 
  await axios.post( url ,{ headers: { 'Authorization': tokenData} }).then( async function(res){
    const data = res.data;
    const user = data.user;
    // await setUserData(user)
    var token = data.accessToken;
    let refreshtoken = data.refreshToken;
    localStorage.setItem("token", token);
    var dataUser = JSON.stringify(user);
    localStorage.setItem("user", dataUser);
    localStorage.setItem("refreshToken",refreshtoken);
    return token;
  }).catch(function(error){
      console.log(error.response.data.message)
  })
}


export const axiosNode = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API,
  // other configurations
})

axiosNode.interceptors.request.use(request => {
  const accessToken = localStorage.getItem('token');
  if (accessToken) {
    request.headers['Authorization'] = `Bearer ${accessToken}`;
  }
  return request;
}, error => {
  return Promise.reject(error);
});

axiosNode.interceptors.response.use(
  (response) => response,
  async(error) => {
    if (error.response && error.response.status === 401) {
      try{
        const newToken = await handleRefreshToken();
        error.config.headers['Authorization'] = `Bearer ${newToken}`;
        return axiosNode(error.config);
      }catch(refreshError){
        throw refreshError;
      }      
    }
    return Promise.reject(error)
  },
)

