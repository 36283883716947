import { Drawer, ListItemButton } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import PasswordIcon from "@mui/icons-material/Password";
import axios from "axios";
import ResetPasswordDialog from "./ResetPasswordDialog";
import { useState } from "react";
import { useNavigate } from "react-router";

export const SettingsBar: React.FC<{
  open: boolean;
  handleClose: Function;
}> = ({ open, handleClose }) => {
  const navigate = useNavigate();
  const [resetPasswordDialogOpen, setResetPasswordDialogOpen] = useState(false);
  const logout = async () => {
    const refreshToken = localStorage.getItem("refreshToken");
    const token = localStorage.getItem("token");
    const url = (process.env.REACT_APP_AUTH_API + "/api/auth/logout") as string;
    const body = { refreshToken };
    const tokenData: string = `Bearer ${token}`;
    axios
      .post(url, body, {
        headers: { Authorization: tokenData },
      })
      .catch((err) => {
        console.log(err.message);
        // alert(err);
      })
      .finally(() => {
        localStorage.clear();
        navigate("/login");
      });
  };

  return (
    <>
      <Drawer anchor="right" open={open} onClose={() => handleClose()}>
        <div className="bg-primary w-[250px] h-full flex flex-col justify-between">
          <div>
            <ListItemButton onClick={() => setResetPasswordDialogOpen(true)}>
              <div className="flex gap-3 py-3 text-white">
                <PasswordIcon />
                <p>Reset Password</p>
              </div>
            </ListItemButton>
          </div>
          <div>
            <ListItemButton onClick={logout}>
              <div className="cursor-pointer flex justify-center items-center py-3 text-right">
                <div>
                  <LogoutIcon htmlColor="white" />
                </div>
                <p className="mx-2 text-white text-right">Logout</p>
              </div>
            </ListItemButton>
          </div>
        </div>
      </Drawer>
      <ResetPasswordDialog
        open={resetPasswordDialogOpen}
        handleClose={() => setResetPasswordDialogOpen(false)}
        handleSubmits={() => {}}
      />
    </>
  );
};

export default SettingsBar;
