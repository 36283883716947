import { useContext, useEffect, useState } from "react";
import { ChatContext } from "../context/ChatContext";
import { ws } from "../ws";
// import collect from 'collect.js'
//import component
import { ChatInput } from "../components/ChatInput";
import { ChatBubble } from "../components/ChatBubble";
import { UserContext } from "../context/UserContext";
import { GroupList } from "../components/GroupList";
import { saveAs } from "file-saver";
//import assset
import mathMavensLogo from "../assets/images/math-mavens-logo.png";
import chatIcon from "../assets/images/chat.png";
import userIcon from "../assets/images/user.webp";

import classNames from "classnames";
import { ChatDateBubble } from "../components/ChatDateBubble";
import axios from "axios";
import { addConversationsAction } from "../reducers/conversationActions";
import { checkIsSameDay, checkIsToday, formattedDate } from "../utils/time";
import { ChatState, IMessage } from "../model/chat";
import {
  Button,
  Drawer,
  IconButton,
  ListItem,
  ListItemButton,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import MenuIcon from "@mui/icons-material/Menu";
import LogoutIcon from '@mui/icons-material/Logout';
import PasswordIcon from '@mui/icons-material/Password';
import { truncateText } from "../utils/string";
import SettingsBar from "../components/SettingsBar";
import { ToastContainer } from "react-toastify";

const Chat = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {
    fetchGroups,
    groups,
    roomStatus,
    chats,
    conversationId,
    newChat,
    disableChat,
    joinRoom,
    broadcast,
    joinedRoomId,
    setJoinedRoomId,
  } = useContext(ChatContext);
  const { user } = useContext(UserContext);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [settingsDrawerOpen, setSettingsDrawerOpen] = useState(false);
  useEffect(() => {
    if (joinedRoomId) {
      joinRoom(joinedRoomId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [joinedRoomId, user.id]);



  const download = (url: any, filename: any) => {
    // var element = document.createElement("a");
    // var file = new Blob(
    //   [
    //     url
    //   ],
    //   { type: "image/*" }
    // );
    // element.href = URL.createObjectURL(file);
    // element.download = filename;
    // element.click();
    let urlData = url;
    saveAs(urlData, filename);
  };

  const checkRequireDate = (
    currTime: number,
    chats: ChatState,
    index: number
  ) => {
    if (chats.messages.length > index + 1) {
      if (
        ((index !== 0 &&
          !checkIsSameDay(currTime, chats.messages[index + 1].created_at) &&
          !checkIsSameDay(currTime, chats.messages[index - 1].created_at)) ||
          (index !== 0 &&
            !checkIsSameDay(currTime, chats.messages[index - 1].created_at) &&
            checkIsSameDay(currTime, chats.messages[index + 1].created_at)) ||
          (index === 0 &&
            !checkIsSameDay(currTime, chats.messages[index + 1].created_at)) ||
          (index === 0 &&
            checkIsSameDay(currTime, chats.messages[index + 1].created_at))) &&
        !checkIsToday(currTime)
      ) {
        return 1;
      } else if (
        index > 0 &&
        !checkIsSameDay(currTime, chats.messages[index - 1].created_at) &&
        checkIsToday(currTime)
      ) {
        return 2;
      } else return 3;
    } else if (chats.messages.length === index + 1) {
      if (chats.messages.length === 1) {
        if (checkIsToday(currTime)) {
          return 2;
        } else return 1;
      } else if (
        !checkIsSameDay(currTime, chats.messages[index - 1].created_at) &&
        !checkIsToday(currTime)
      ) {
        return 1;
      } else if (
        !checkIsSameDay(currTime, chats.messages[index - 1].created_at) &&
        checkIsToday(currTime)
      ) {
        return 2;
      } else return 3;
    } else return 3;
  };

  useEffect(() => {
    if (user.id) fetchGroups();

    document.addEventListener("keydown", (ev) => {
      if (ev.key === "Escape") {
        localStorage.removeItem("joined_room_id");
        setJoinedRoomId("");
      }
    });
  }, [user]);

  return (
    <>
    <ToastContainer/>
      <div className="min-h-screen">
        <div className="py-[15px] px-4 bg-primary text-white w-full capitalize flex justify-between items-center h-[60px]">
          <div className="hidden sm:flex">
            <div className="flex justify-start items-end">
              <img
                className="w-8 h-full"
                src={mathMavensLogo}
                alt="Math_Mavens_logo"
              />
              <p className="ml-1 text-primary text-center not-italic font-semibold text-md">
                Chat App
              </p>
              <p className="ml-5">hello, {user.name}</p>
            </div>
          </div>
          <div className="block sm:hidden">
            <IconButton onClick={() => setDrawerOpen(true)}>
              <MenuIcon htmlColor="white"/>
            </IconButton>
          </div>

          <IconButton onClick={() => setSettingsDrawerOpen(true)}>
            <SettingsIcon htmlColor="white"/>
          </IconButton>
        </div>
        <div className="flex h-[calc(100vh-60px)]">
          <Drawer
            className="sm:hidden"
            open={drawerOpen}
            onClose={() => setDrawerOpen(false)}
          >
            <div className="bg-primary h-[60px] flex flex-col items-center justify-center text-white font-bold overflow-hidden w-[200px]">
              <h2>
                Hello, {truncateText(user.name + "", 10)}
              </h2>
            </div>
            <GroupList
              groups={groups}
              handleClick={() => setDrawerOpen(false)}
            />
          </Drawer>
          <div className="border-0 w-3/12 h-[calc(100vh-60px)] overflow-y-auto hidden sm:block">
            <div className=" flex-col h-full" data-testid="chat">
              {groups ? <GroupList groups={groups} /> : ""}
            </div>
          </div>

          <div className="flex flex-col w-full relative">
            {joinedRoomId ? (
              <>
                <div className="flex border h-full">
                  <div
                    className={classNames(
                      "flex h-[calc(100vh-140px)] w-full flex-col overflow-y-auto flex-grow-0 p-1",
                      {
                        "bg-slate-100": disableChat,
                        "bg-white": !disableChat,
                      }
                    )}
                    id="chat-container"
                  >
                    {chats.messages.length === 0 && !disableChat ? (
                      <div className="mx-auto mt-52 flex-col justify-center font-semibold h-full items-center">
                        <img
                          className="w-16 h-16 object-contain ml-4"
                          src={chatIcon}
                          alt="chat"
                        />
                        <p>No messages</p>
                      </div>
                    ) : (
                      chats.messages.map((message: IMessage, index: number) => {
                        if (
                          checkRequireDate(message.created_at, chats, index) ===
                          1
                        ) {
                          return (
                            <>
                              <ChatDateBubble>
                                {formattedDate(message.created_at)}
                              </ChatDateBubble>
                              <ChatBubble
                                message={message}
                                key={
                                  message.created_at +
                                  (message?.sender_name || "anonymous") +
                                  index
                                }
                              />
                            </>
                          );
                        } else if (
                          checkRequireDate(message.created_at, chats, index) ===
                          2
                        ) {
                          return (
                            <>
                              <ChatDateBubble>Today</ChatDateBubble>
                              <ChatBubble
                                message={message}
                                key={
                                  message.created_at +
                                  (message?.sender_name || "anonymous") +
                                  index
                                }
                              />
                            </>
                          );
                        } else {
                          return (
                            <ChatBubble
                              message={message}
                              key={
                                message.created_at +
                                (message?.sender_name || "anonymous") +
                                index
                              }
                            />
                          );
                        }
                      })
                    )}
                  </div>
                  {!disableChat && (
                    <div className="border w-4/12 h-full p-2 options-sidebar">
                      {/* option */}
                      <div className="flex justify-center items-center p-4">
                        <img
                          className="w-1/2 h-full"
                          src={userIcon}
                          alt="user_icon"
                        />
                      </div>
                      <div className="flex justify-center items-center">
                        {/* <div className="rounded-xl w-3 h-3 bg-green-400 "></div> */}
                        <p className="uppercase text-md font-bold ml-2 text-slate-600">
                          {conversationId.initials
                            ? conversationId.initials
                            : ""}
                        </p>
                      </div>
                      <div className="flex justify-center items-center">
                        <p className="text-xs font-normal ml-2 text-slate-600">
                          {conversationId.channel_id
                            ? conversationId.channel_id
                            : ""}
                        </p>
                      </div>
                      <div className="flex justify-center items-center">
                        <p className="capitalize text-md font-semibold ml-2 text-slate-600">
                          {conversationId.recipient
                            ? conversationId.recipient
                            : ""}
                        </p>
                      </div>
                      <div className="mt-4 p-4 overflow-y-auto h-[350px] w-full">
                        {chats.messages.length !== 0 && !disableChat && (
                          <p className="text-md font-bold text-slate-600">
                            Galery
                          </p>
                        )}
                        {chats.messages.length !== 0 && !disableChat && (
                          <div className="flex flex-wrap">
                            {chats.messages.map(
                              (message: IMessage, index: number) => (
                                <div key={index}>
                                  {message.attachment && (
                                    <div className="bg-white w-16 h-16 flex justify-center items-center m-2 p-3 shadow-main rounded-sm">
                                      {message.attachment.type === "jpeg" ||
                                      message.attachment.type === "png" ||
                                      message.attachment.type === "webp" ? (
                                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                        <a
                                          onClick={() =>
                                            download(
                                              process.env
                                                .REACT_APP_BACKEND_API +
                                                "/uploads/" +
                                                message.attachment.name,
                                              message.attachment.name
                                            )
                                          }
                                          rel="noreferrer"
                                          className="flex items-center justify-center cursor-pointer"
                                        >
                                          <img
                                            className="object-contain"
                                            src={
                                              process.env
                                                .REACT_APP_BACKEND_API +
                                              "/uploads/" +
                                              message.attachment.name
                                            }
                                            alt={message.attachment.name}
                                          />
                                        </a>
                                      ) : (
                                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                        <a
                                          onClick={() =>
                                            download(
                                              process.env
                                                .REACT_APP_BACKEND_API +
                                                "/uploads/" +
                                                message.attachment.name,
                                              message.attachment.name
                                            )
                                          }
                                          rel="noreferrer"
                                          className="flex items-center justify-center cursor-pointer"
                                        >
                                          <div className="object-contain">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="16"
                                              height="16"
                                              fill="currentColor"
                                              className="bi bi-file-earmark-arrow-down-fill w-full h-full fill-sky-300"
                                              viewBox="0 0 16 16"
                                            >
                                              <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zm-1 4v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 0 1 .708-.708L7.5 11.293V7.5a.5.5 0 0 1 1 0z" />
                                            </svg>
                                          </div>
                                          <p className="text-[6px]">
                                            {message.attachment.name}
                                          </p>
                                        </a>
                                      )}
                                    </div>
                                  )}
                                </div>
                              )
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <ChatInput conversation_id={conversationId} disabled={false} />
              </>
            ) : (
              <div className="mx-auto mt-5 flex flex-col justify-center font-semibold h-full items-center">
                <img
                  className="w-16 h-16 object-contain"
                  src={chatIcon}
                  alt="chat"
                />
                <p>Join Room to start messaging</p>
              </div>
            )}
          </div>
        </div>
        <SettingsBar open={settingsDrawerOpen} handleClose={()=>setSettingsDrawerOpen(false)}/>
      </div>
    </>
  );
};

export default Chat;
