import { ErrorMessage } from "@hookform/error-message";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link } from "@mui/material";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { Link as RouterLink, useNavigate, useParams } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import mathMavensLogo from "../assets/images/math-mavens-logo.png";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

type LoginType = {
    email: string;
    password: string;
    retype_password: string;
  };

const ForgetPasswordConfirm = () => {
    const params = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isSent,setIsSent] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const navigate = useNavigate();
  const schema = yup.object().shape({
    email: yup.string().email().required().label("Email"),
    password: yup.string().min(8).required().label("Password"),
    retype_password: yup.string().oneOf([yup.ref('password')],"Must match New Password").min(8).required().label("Retype Password"),
  });

  const {
    handleSubmit: tes,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const handleSubmit = async (data: LoginType) => {
    setIsLoading(true);
    const url = (process.env.REACT_APP_AUTH_API + "/api/auth/reset-password/confirm") as string;
    // e.preventDefault();
    axios
      .post(url, {
        email: data.email,
        token: params.token,
        password: data.password,
        retype_password: data.retype_password,
      })
      .then(async (res) => {
        setIsSent(true);
        toast("Password has been succesfully updated", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            type: "success",
          });
        // return navigate("/");
      })
      .catch((err) => {
        console.log(err);
        toast(err.response.data.message || "Something went wrong", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          type: "error",
        });
        console.log(err.message);
        //  alert(err)
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="App section-node">
        <ToastContainer />
        
      {!isSent && <section className="w-full">
        <div className="py-[16rem] flex-col justify-center items-center 2xl:py-[32rem]">
          <div className="flex justify-center items-baseline my-4">
            <img
                  className="w-12 h-full"
                  src={mathMavensLogo}
                  alt="Math_Mavens_logo"
                />
            <h1 className="ml-2 text-primary text-center not-italic font-bold leading-10 text-xl">
              Chat App
            </h1>
          </div>
          <form onSubmit={tes(handleSubmit)}>
            <h2>Reset Password</h2>
            <div className="mb-4">
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <>
                    <input
                      placeholder="Email"
                      type="text"
                      {...field}
                      className="border h-12 w-81 rounded-full outline-none px-4 shadow-main text-sm"
                    />
                    <ErrorMessage
                      errors={errors}
                      name="email"
                      render={({ message }) => (
                        <p className="text-red-500 text-xs">{message}</p>
                      )}
                    />
                  </>
                )}
              />
            </div>
            <div className="mb-4">
                  <Controller
                    name="password"
                    control={control}
                    render={({ field }) => (
                      <>
                        <input
                          placeholder="Password"
                          type={passwordShown ? "text" : "password"}
                          className="border h-12 w-81 rounded-full outline-none px-4 shadow-main text-sm ml-6 "
                          {...field}
                        />
                        <button
                          className="relative right-9  text-slate-400"
                          type="button"
                          onClick={togglePassword}
                        >
                          {passwordShown ? (
                            <VisibilityOffIcon />
                          ) : (
                            <VisibilityIcon />
                          )}
                        </button>
                        <ErrorMessage
                          errors={errors}
                          name="password"
                          render={({ message }) => (
                            <p className="text-red-500 text-xs">{message}</p>
                          )}
                        />
                      </>
                    )}
                  />
                </div>
                <div className="mb-4">
                  <Controller
                    name="retype_password"
                    control={control}
                    render={({ field }) => (
                      <>
                        <input
                          placeholder="Retype Password"
                          type={passwordShown ? "text" : "password"}
                          className="border h-12 w-81 rounded-full outline-none px-4 shadow-main text-sm ml-6 "
                          {...field}
                        />
                        <button
                          className="relative right-9  text-slate-400"
                          type="button"
                          onClick={togglePassword}
                        >
                          {passwordShown ? (
                            <VisibilityOffIcon />
                          ) : (
                            <VisibilityIcon />
                          )}
                        </button>
                        <ErrorMessage
                          errors={errors}
                          name="retype_password"
                          render={({ message }) => (
                            <p className="text-red-500 text-xs">{message}</p>
                          )}
                        />
                      </>
                    )}
                  />
                </div>
            <div className="action mb-4">
              <button
                disabled={isLoading}
                type="submit"
                className="shadow-main button-primary rounded-full h-12 w-81 shadow-md ml-2"
              >
                {isLoading ? "Loading..." : "Confirm"}
              </button>
            </div>
          </form>
        </div>
      </section>}
      {isSent && <section className="w-full">
        <div className="py-[16rem] flex-col justify-center items-center 2xl:py-[32rem]">
          <div className="flex justify-center items-baseline my-4">
            <img
                  className="w-12 h-full"
                  src={mathMavensLogo}
                  alt="Math_Mavens_logo"
                />
          </div>
         <h1>Password has been succesfully reset.</h1>
         <Link component={RouterLink} to="/login">
                Back to Login Page
              </Link>
        </div>
      </section>}
    </div>
  );
};

export default ForgetPasswordConfirm;
