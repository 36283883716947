import { useEffect, useState } from "react";
// import logo from './logo.svg';
import mathMavensLogo from "./assets/images/math-mavens-logo.png";
import chatIcon from "./assets/images/chat.png";
import "./App.css";
// import socketIO from "socket.io-client"
import Chat from "./pages/Chat";
import { ChatProvider } from "./context/ChatContext";
import { UserProvider } from "./context/UserContext";
import axios from "axios";

type LoginType = {
  email: string;
  password: string;
};

function App() {
  const [userData, setUserData] = useState<any>({});
  const [isLoading, setIsLoading] = useState(true);
  const tempToken = localStorage.getItem("token");




  const handleRefreshToken = async () => {
    const token = await localStorage.getItem("refreshToken");
    const url = (process.env.REACT_APP_AUTH_API +
      "/api/auth/refresh") as string;
    const tokenData: string = `Bearer ${token}`;
    axios
      .post(url, {},{ headers: { 'Authorization': tokenData } })
      .then(async function (res) {
        const data = res.data;
        const user = data.user;
        setUserData(user);
        const token = data.accessToken;
        const refreshtoken = data.refreshToken;
        const portalToken = data.portalAccessToken;
        localStorage.setItem("token", token);
        const dataUser = JSON.stringify(user);
        localStorage.setItem("user", dataUser);
        localStorage.setItem("refreshToken", refreshtoken);
        localStorage.setItem("portalToken",portalToken);
      })
      .catch(function (error) {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("portalToken");
        console.log(error.response.data.message);
      });
  };

  const getUserData = async () => {
    setIsLoading(true)
    const token = await localStorage.getItem("token");
    const url = (process.env.REACT_APP_AUTH_API + "/api/auth/me") as string;
    const tokenData: string = `Bearer ${token}`;
    axios
      .get(url, { headers: { 'Authorization': tokenData } })
      .then(async function (response) {
        await setUserData(response.data);
      })
      .catch(function (error) {
        console.log(error, "ERRR");
        if (error.status === 401) handleRefreshToken();
        // console.log(error.response.data.message)
      }).finally(()=>{
        setIsLoading(false)
      });
  };

  useEffect(() => {
    getUserData();
  }, []);

  if(!isLoading)
    return (
     <UserProvider initialUser={userData}>
        <ChatProvider>
          <Chat />
        </ChatProvider>
      </UserProvider>
    );

    return (
      <h1>Loadingg...</h1>
    )
}

export default App;
