import React, { ReactNode, useEffect } from 'react';
import { Navigate } from 'react-router';

const NoAuthGuard : React.FC<{component:ReactNode}> = ({component} )  => {
    const tempToken = localStorage.getItem("token");
    if(tempToken){
        return <Navigate to="/" replace/>
    }
    return <React.Fragment>{component}</React.Fragment>

}

export default NoAuthGuard;