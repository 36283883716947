import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { DisableDialogLeave } from "../utils/mui";
import axios from "axios";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { axiosNode } from "../helper/axiosInstance";
import { toast } from "react-toastify";

interface IFormInput {
  curr_password: string;
  new_password: string;
  re_password: string;
}

export const ResetPasswordDialog: React.FC<{
  open: boolean;
  handleClose: Function;
  handleSubmits: Function;
}> = ({ open, handleClose, handleSubmits }) => {
  const [isLoading, setIsLoading] = useState(false);

  const schema = yup.object().shape({
    curr_password: yup.string().min(8).required().label("Current Password"),
    new_password: yup.string().min(8).required().label("New Password"),
    re_password: yup.string().oneOf([yup.ref('new_password')],"Must match New Password").min(8).required().label("Retype Password"),
  });

  const {
    reset,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IFormInput>({
    resolver:yupResolver(schema)
  });
  const onSubmit = (data: IFormInput) => handleReset(data);

  const handleCancel = async () => {
    reset();
    handleClose();
  };

  const handleReset = async (data: IFormInput) => {
    setIsLoading(true);
    const portalToken = localStorage.getItem("portalToken");
    const url = (process.env.REACT_APP_AUTH_API + "/api/auth/change-password") as string;
    axiosNode
      .post(url, {
        portal_token: portalToken,
        current_password: data.curr_password,
        new_password: data.new_password,
        retype_password: data.re_password,
      })
      .then(async (res) => {
        toast(res.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          type: "success",
        });
        handleCancel();
      })
      .catch((err) => {
        console.log(err.message);
        toast(err.response.data.message || "Something went wrong", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          type: "error",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Dialog
      open={open}
      onClose={(e, r) => DisableDialogLeave(r, handleClose)}
      disableEscapeKeyDown
    >
      <DialogTitle>Reset Password</DialogTitle>
      <DialogContent className="max-w-[500px]">
        <DialogContentText>
          Type in your new password to reset it.
        </DialogContentText>
        <form onSubmit={handleSubmit(onSubmit)}></form>
        <Controller
          name="curr_password"
          control={control}
          render={({ field, fieldState }) => (
            <>
              <TextField
                autoFocus
                required
                margin="dense"
                id="curr_password"
                label="Current Password"
                type="password"
                fullWidth
                variant="standard"
                error={fieldState.invalid}
                {...field}
              />
              <ErrorMessage
                errors={errors}
                name="curr_password"
                render={({ message }) => (
                  <p className="text-red-500 text-xs">{message}</p>
                )}
              />
            </>
          )}
        />
        <Controller
          name="new_password"
          control={control}
          render={({ field, fieldState }) => (
            <>
              <TextField
                autoFocus
                required
                margin="dense"
                id="new_password"
                label="New Password"
                type="password"
                fullWidth
                variant="standard"
                error={fieldState.invalid}
                {...field}
              />
              <ErrorMessage
                errors={errors}
                name="new_password"
                render={({ message }) => (
                  <p className="text-red-500 text-xs">{message}</p>
                )}
              />
            </>
          )}
        />
        <Controller
          name="re_password"
          control={control}
          render={({ field, fieldState }) => (
            <>
              <TextField
                autoFocus
                required
                margin="dense"
                id="re_password"
                label="Retype Password"
                type="password"
                fullWidth
                variant="standard"
                error={fieldState.invalid}
                {...field}
              />
              <ErrorMessage
                errors={errors}
                name="re_password"
                render={({ message }) => (
                  <p className="text-red-500 text-xs">{message}</p>
                )}
              />
            </>
          )}
        />
      </DialogContent>
      <DialogActions>
        <LoadingButton loading={isLoading} onClick={() => handleCancel()}>
          Cancel
        </LoadingButton>
        <LoadingButton
          variant="contained"
          loading={isLoading}
          onClick={handleSubmit(onSubmit)}
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ResetPasswordDialog;
