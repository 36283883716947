import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
  } from "@mui/material";
  import { DisableDialogLeave } from "../utils/mui";
  import axios from "axios";
  import { useState } from "react";
  import { LoadingButton } from "@mui/lab";
  import { Controller, useForm } from "react-hook-form";
  import { ErrorMessage } from "@hookform/error-message";

  interface IFormInput {
    password: string;
  }

const ResetPasswordNoAuth: React.FC<{
  open: boolean;
  handleClose: Function;
  handleSubmits: Function;
}> = ({ open, handleClose, handleSubmits }) => {
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IFormInput>({
    defaultValues: {
      password: "",
    },
  });
  const onSubmit = (data: any) => console.log(data);

  const handleCancel = async () => {
    setPassword("");
    handleClose();
  };

  const handleReset = async () => {
    setIsLoading(true);
    const url = (process.env.REACT_APP_AUTH_API +
      "/api/users/${this.userID}/reset-password-by-admin") as string;
    axios
      .post(url, {
        password: password,
      })
      .then(async (res) => {})
      .catch((err) => {
        console.log(err.message);
        alert(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

    return (
        <Dialog
      open={open}
      onClose={(e, r) => DisableDialogLeave(r, handleClose)}
      disableEscapeKeyDown
    >
      <DialogTitle>Reset Password</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Type in your new password to reset it.
        </DialogContentText>
        <form onSubmit={handleSubmit(onSubmit)}></form>
        <Controller
          name="password"
          control={control}
          rules={{
            required: { value: true, message: "Password is Required" },
            minLength: { value: 8, message: "Min 8 characters" },
          }}
          render={({ field, fieldState }) => (
            <>
              <TextField
                autoFocus
                required
                margin="dense"
                id="password"
                label="Password"
                type="password"
                fullWidth
                variant="standard"
                error={fieldState.invalid}
                {...field}
              />
              <ErrorMessage
                errors={errors}
                name="password"
                render={({ message }) => <p className="text-red-500 text-xs">{message}</p>}
              />
            </>
          )}
        />
      </DialogContent>
      <DialogActions>
        <LoadingButton loading={isLoading} onClick={() => handleCancel()}>
          Cancel
        </LoadingButton>
        <LoadingButton variant="contained" loading={isLoading} onClick={handleSubmit(onSubmit)}>
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
    )
}

export default ResetPasswordNoAuth;