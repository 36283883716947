import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createTheme, ThemeProvider } from "@mui/material";
import { BrowserRouter, Navigate, Route, Routes } from "react-router";
import AuthGuard from "./guards/AuthGuard";
import NoAuthGuard from "./guards/NoAuthGuard";
import Login from "./login";
import ForgetPassword from "./pages/ForgetPassword";
import ForgetPasswordConfirm from "./pages/ForgetPasswordConfirm";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const theme = createTheme({
  palette: {
    primary: {
      light: "#00b6dd",
      main: "#00b6dd",
      dark: "#00b6dd",
      contrastText: "#fff",
    },
  },
});

root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <Routes>
        <Route
          path="/login"
          element={<NoAuthGuard component={<Login />}></NoAuthGuard>}
        />
        <Route index element={<AuthGuard component={<App />}></AuthGuard>} />
        <Route
          path="forget-password"
          element={<NoAuthGuard component={<ForgetPassword />} />}
        ></Route>
        <Route
          path="forget-password/:token"
          element={<NoAuthGuard component={<ForgetPasswordConfirm />} />}
        ></Route>
        <Route
        path="*" element={<Navigate to="/"/>}>
        </Route>
      </Routes>
    </ThemeProvider>
  </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
