import { useContext, useEffect, useState } from "react";
import { UserContext } from "../context/UserContext";
import { ChatContext } from "../context/ChatContext";
import classNames from "classnames";
import { handleDisplayTime } from "../utils/time";
import { GroupState, GroupType } from "../model/chat";
import { Button, Input, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ArchiveIcon from "@mui/icons-material/Archive";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
export const GroupList: React.FC<{
  groups: GroupState;
  handleClick?: Function;
}> = ({ groups, handleClick }) => {
  const { user } = useContext(UserContext);
  const { joinRoom, joinedRoomId } = useContext(ChatContext);
  const [activeGroups, setActiveGroups] = useState<GroupType[]>([]);
  const [archivedGroups, setArchivedGroups] = useState<GroupType[]>([]);
  const [searchGroup, setSearchGroup] = useState("");
  const [isArchive, setIsArchive] = useState(false);
  const handleButtonClick = (id: string) => {
    joinRoom(id);
    if (handleClick) handleClick();
  };
  useEffect(() => {
    const temp = groups.groups;
    if(searchGroup.trim().length > 0){
        
    }
    const activeGroups = temp.filter((group) => {
        if(searchGroup.trim().length > 0){
            const groupName = group?.group_name.toLowerCase();
            const lastMessage = group?.latest_message?.message.toLowerCase() || "";
            const lastSender = group?.latest_message?.sender_name.toLowerCase() || "";
            const searchQuery = searchGroup?.toLowerCase();
            return group.status === "active" && (
                groupName?.includes(searchQuery) || 
                lastMessage?.includes(searchQuery) ||
                lastSender?.includes(searchQuery)
            );
        }
      return group.status === "active";
    });
    activeGroups.sort((a,b)=> 
    {
      if(b.latest_message?.created_at && a.latest_message?.created_at) return b.latest_message?.created_at - a.latest_message?.created_at
      if(b.latest_message?.created_at && !a.latest_message?.created_at) return 1;
      return -1
    }

    )
    setActiveGroups(activeGroups);
    const archivedGroups = temp.filter((group) => {
        if(searchGroup.trim().length > 0){
            const groupName = group?.group_name.toLowerCase();
            const lastMessage = group?.latest_message?.message.toLowerCase() || "";
            const lastSender = group?.latest_message?.sender_name.toLowerCase() || "";
            const searchQuery = searchGroup?.toLowerCase();
            return group.status === "archived" && (
                groupName?.includes(searchQuery) || 
                lastMessage?.includes(searchQuery) ||
                lastSender?.includes(searchQuery)
            );
        }
      return group.status === "archived";
    });
    archivedGroups.sort((a,b)=> b.latest_message?.created_at - a.latest_message?.created_at)
    setArchivedGroups(archivedGroups);
  }, [groups.groups,searchGroup]);

  return (
    <>
      <div className="flex flex-col mb-5">
        <Input
          startAdornment={
            <InputAdornment position="start" >
              <SearchIcon />
            </InputAdornment>
          }
          placeholder="Search..."
          value={searchGroup}
          onChange={(e) => setSearchGroup(e.target.value)}
        />
        <Button onClick={()=>setIsArchive(!isArchive)}>
          <div className="flex gap-1 items-center">
            {isArchive ? (
              <>
                <ArrowBackIcon />
                <p>Back</p>
              </>
            ) : (
              <>
              <ArchiveIcon />
              <p>Archived</p>
                
              </>
            )}
          </div>
        </Button>
      </div>
      <p className="text-center mb-3 text-sm font-semibold">{isArchive ? "Archived" :"Recent Chats"}</p>
      {isArchive ? (
        archivedGroups.length > 0 ? archivedGroups.map((group, implement: number) => 
          (
            <div key={group.id}>
              <button
                className={classNames(
                  "flex flex-col w-[200px] sm:w-full md:flex-row items-center  py-2 md:px-3 transition-colors duration-200 gap-x-2  focus:outline-none",
                  {
                    "hover:bg-sky-300": implement % 2 === 0,
                    "hover:bg-green-300": implement % 2 === 1,
                    "bg-primary": group.id === Number(joinedRoomId),
                  }
                )}
                onClick={(e) => handleButtonClick(String(group.id))}
              >
                <div
                  className={classNames(
                    "flex justify-center items-center rounded-full text-white bg-primary md:py-5 px-5 w-12 h-12",
                    {
                      "bg-primary": implement % 2 === 0,
                      "bg-secondary": implement % 2 === 1,
                    }
                  )}
                >
                  <span className="relative">{group.group_name[0]}</span>
                </div>
                <div className="w-full text-center justify-center md:text-left flex md:justify-between md:items-center">
                  <div>
                    <h1
                      className="md:ml-2 text-sm font-medium text-black capitalize dark:text-white hover:text-white"
                      style={{ color: "black" }}
                    >
                      {group.group_name ? group.group_name : "no-name"}
                    </h1>
                    {!group?.latest_message?.attachment ? (
                      group?.latest_message ? (
                        <p
                          className="md:ml-2 text-xs font-normal text-slate-100 capitalize dark:text-white hover:text-white"
                          style={{ color: "grey" }}
                        >
                          {group?.latest_message?.sender_name}:{" "}
                          {group?.latest_message?.message}
                        </p>
                      ) : (
                        ""
                      )
                    ) : (
                      <div>
                        {/* {(group.message.attachment.type === 'png' || group.message.attachment.type === 'jpeg' || group.message.attachment.type === 'jpg' || group.message.attachment.type === 'webp' ) ? (
                                        <p className="text-xs flex justify-start items-center font-normal text-slate-100" style={{ color: "grey" }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="md:ml-2 bi bi-card-image w-3 h-3 my-1" viewBox="0 0 16 16">
                                            <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                            <path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13zm13 1a.5.5 0 0 1 .5.5v6l-3.775-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12v.54A.505.505 0 0 1 1 12.5v-9a.5.5 0 0 1 .5-.5h13z"/>
                                        </svg>
                                        {group.message.attachment.name ? group.message.attachment.name : 'image'}
                                    </p>
                                    ) : (
                                        <p className="text-xs flex justify-start items-center font-normal text-slate-100" style={{ color: "grey" }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="md:ml-2 bi bi-file-earmark-text-fill  w-3 h-3 my-1" viewBox="0 0 16 16">
                                            <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM4.5 9a.5.5 0 0 1 0-1h7a.5.5 0 0 1 0 1h-7zM4 10.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 1 0-1h4a.5.5 0 0 1 0 1h-4z"/>
                                        </svg>
                                        {group.message.attachment.name ? group.message.attachment.name : 'file'}
                                        </p>
                                    )} */}
                      </div>
                    )}
                  </div>
                  <div className="hidden lg:block">
                    <p
                      className="md:ml-2 text-[10px] text-right font-normal text-grey capitalize dark:text-white hover:text-white"
                      style={{ color: "grey" }}
                    >
                      {group.latest_message?.created_at ? handleDisplayTime(group.latest_message?.created_at) : ""}
                    </p>
                  </div>
                </div>
              </button>
            </div>
          )
          
        ) : <div className="text-center">No Archived Groups</div>
      ):
      
      (activeGroups.map((group, implement: number) => (
        <div key={group.id}>
          <button
            className={classNames(
              "flex flex-col w-[200px] sm:w-full md:flex-row items-center  py-2 md:px-3 transition-colors duration-200 gap-x-2  focus:outline-none",
              {
                "hover:bg-sky-300": implement % 2 === 0,
                "hover:bg-green-300": implement % 2 === 1,
                "bg-primary": group.id === Number(joinedRoomId),
              }
            )}
            onClick={(e) => handleButtonClick(String(group.id))}
          >
            <div
              className={classNames(
                "flex justify-center items-center rounded-full text-white bg-primary md:py-5 px-5 w-12 h-12",
                {
                  "bg-primary": implement % 2 === 0,
                  "bg-secondary": implement % 2 === 1,
                }
              )}
            >
              <span className="relative">{group.group_name[0]}</span>
            </div>
            <div className="w-full text-center justify-center md:text-left flex md:justify-between md:items-center">
              <div>
                <h1
                  className="md:ml-2 text-sm font-medium text-black capitalize dark:text-white hover:text-white"
                  style={{ color: "black" }}
                >
                  {group.group_name ? group.group_name : "no-name"}
                </h1>
                {!group?.latest_message?.attachment ? (
                  group?.latest_message ? (
                    <p
                      className="md:ml-2 text-xs font-normal text-slate-100 capitalize dark:text-white hover:text-white"
                      style={{ color: "grey" }}
                    >
                      {group?.latest_message?.sender_name}:{" "}
                      {group?.latest_message?.message}
                    </p>
                  ) : (
                    ""
                  )
                ) : (
                  <div>
                    {/* {(group.message.attachment.type === 'png' || group.message.attachment.type === 'jpeg' || group.message.attachment.type === 'jpg' || group.message.attachment.type === 'webp' ) ? (
                                    <p className="text-xs flex justify-start items-center font-normal text-slate-100" style={{ color: "grey" }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="md:ml-2 bi bi-card-image w-3 h-3 my-1" viewBox="0 0 16 16">
                                        <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                        <path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13zm13 1a.5.5 0 0 1 .5.5v6l-3.775-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12v.54A.505.505 0 0 1 1 12.5v-9a.5.5 0 0 1 .5-.5h13z"/>
                                    </svg>
                                    {group.message.attachment.name ? group.message.attachment.name : 'image'}
                                </p>
                                ) : (
                                    <p className="text-xs flex justify-start items-center font-normal text-slate-100" style={{ color: "grey" }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="md:ml-2 bi bi-file-earmark-text-fill  w-3 h-3 my-1" viewBox="0 0 16 16">
                                        <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM4.5 9a.5.5 0 0 1 0-1h7a.5.5 0 0 1 0 1h-7zM4 10.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 1 0-1h4a.5.5 0 0 1 0 1h-4z"/>
                                    </svg>
                                    {group.message.attachment.name ? group.message.attachment.name : 'file'}
                                    </p>
                                )} */}
                  </div>
                )}
              </div>
              <div className="hidden lg:block">
                <p
                  className="md:ml-2 text-[10px] text-right font-normal text-grey capitalize dark:text-white hover:text-white"
                  style={{ color: "grey" }}
                >
                  {group.latest_message?.created_at ? handleDisplayTime(group.latest_message?.created_at) : ""}
                </p>
              </div>
            </div>
          </button>
        </div>
      ))
      )}



    </>
  );
};
