import { useEffect, useState } from "react";
// import logo from './logo.svg';
import mathMavensLogo from "./assets/images/math-mavens-logo.png";
import chatIcon from "./assets/images/chat.png";
import "./App.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Link } from "@mui/material";
import ResetPasswordNoAuth from "./components/ResetPasswordNoAuth";
import { Navigate, useNavigate,Link as RouterLink } from "react-router";

type LoginType = {
  email: string;
  password: string;
};

const Login = () =>{
  const navigate = useNavigate();
  const [isResetPassword, setIsResetPassword] = useState(false);
  const [login, setIsLogin] = useState<boolean>(false);
  const [userData, setUserData] = useState<any>({});
  const [passwordShown, setPasswordShown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const tempToken = localStorage.getItem("token");

  const handleCloseResetPassword = () => {
    setIsResetPassword(false);
  };

  const schema = yup.object().shape({
    email: yup.string().email().required(),
    password: yup.string().min(8).required(),
  });

  const {
    handleSubmit: tes,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const handleRefreshToken = async () => {
    const token = localStorage.getItem("refreshToken");
    const url = (process.env.REACT_APP_AUTH_API +
      "/api/auth/refresh") as string;
    const tokenData: string = `Bearer ${token}`;
    axios
      .post(url, {},{ headers: { 'Authorization': tokenData } })
      .then(async function (res) {
        setIsLogin(true);
        const data = res.data;
        const user = data.user;
        setUserData(user);
        var token = data.accessToken;
        let refreshtoken = data.refreshToken;
        localStorage.setItem("token", token);
        var dataUser = JSON.stringify(user);
        localStorage.setItem("user", dataUser);
        localStorage.setItem("refreshToken", refreshtoken);
      })
      .catch(function (error) {
        console.log(error.response.data.message);
      });
  };

  const getUserData = async () => {
    const token = localStorage.getItem("token");
    const url = (process.env.REACT_APP_AUTH_API + "/api/auth/me") as string;
    const tokenData: string = `Bearer ${token}`;
    axios
      .get(url, { headers: { 'Authorization': tokenData } })
      .then(async function (response) {
        setUserData(response.data);
        setIsLogin(true);
      })
      .catch(function (error) {
        console.log(error, "ERRR");
        if (error.status === 401) handleRefreshToken();
        // console.log(error.response.data.message)
      });
  };

  useEffect(() => {
    getUserData();
  }, []);

  const handleSubmit = async (data: LoginType) => {
    setIsLoading(true);
    const url = (process.env.REACT_APP_AUTH_API + "/api/auth/login") as string;
    // e.preventDefault();
    axios
      .post(url, {
        email: data.email,
        password: data.password,
      })
      .then(async (res) => {
        const data = res.data;
        const user = data.user;
        const token = data.accessToken;
        const refreshtoken = data.refreshToken;
        const portalToken = data.portalAccessToken;
        localStorage.setItem("token", token);
        const dataUser = JSON.stringify(user);
        localStorage.setItem("user", dataUser);
        localStorage.setItem("refreshToken", refreshtoken);
        localStorage.setItem("portalToken",portalToken);
        return navigate("/")
        // await window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        toast(err.response.data.message || "Something went wrong", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          type: "error",
        });
        console.log(err.message);
        //  alert(err)
      })
      .finally(() => {
        setIsLoading(false);
      });
  };


  return (
    <div className="App">
        <ToastContainer />

        <main className="grid md:grid-cols-2 gap-0">
          <section className="section-left w-full hidden md:block">
            <div className="my-[14.2rem] 2xl:my-[24rem]">
              <div className="image-illustrator">
                <img
                  src={chatIcon}
                  className="w-4/12 h-4/12 drop-shadow flex justify-center items-center mx-auto my-auto"
                  alt="illustration_image"
                />
              </div>
              <div className="desc">
                <h1 className="text-primary text-center not-italic font-bold leading-10 text-md">
                  Welcome to Math Mavens Chat App
                </h1>
                <p className="text-primary text-center not-italic font-normal leading-6 text-md">
                  login now, and lets chat
                </p>
              </div>
            </div>
          </section>
          <section className="section-right w-full">
            <div className="my-[16rem] flex-col justify-center items-center 2xl:my-[32rem]">
              <div className="flex justify-center items-baseline my-4">
                <img
                  className="w-12 h-full"
                  src={mathMavensLogo}
                  alt="Math_Mavens_logo"
                />
                <h1 className="ml-2 text-primary text-center not-italic font-bold leading-10 text-xl">
                  Chat App
                </h1>
              </div>
              <form onSubmit={tes(handleSubmit)}>
                <div className="mb-4">
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <>
                        <input
                          placeholder="Email"
                          type="text"
                          {...field}
                          className="border h-12 w-81 rounded-full outline-none px-4 shadow-main text-sm"
                        />
                        <ErrorMessage
                          errors={errors}
                          name="email"
                          render={({ message }) => (
                            <p className="text-red-500 text-xs">{message}</p>
                          )}
                        />
                      </>
                    )}
                  />
                </div>
                <div className="mb-4">
                  <Controller
                    name="password"
                    control={control}
                    render={({ field }) => (
                      <>
                        <input
                          placeholder="Password"
                          type={passwordShown ? "text" : "password"}
                          className="border h-12 w-81 rounded-full outline-none px-4 shadow-main text-sm ml-6 "
                          {...field}
                        />
                        <button
                          className="relative right-9  text-slate-400"
                          type="button"
                          onClick={togglePassword}
                        >
                          {passwordShown ? (
                            <VisibilityOffIcon />
                          ) : (
                            <VisibilityIcon />
                          )}
                        </button>
                        <ErrorMessage
                          errors={errors}
                          name="password"
                          render={({ message }) => (
                            <p className="text-red-500 text-xs">{message}</p>
                          )}
                        />
                      </>
                    )}
                  />
                </div>
                <div className="mb-4 mx-auto max-w-[344px]">
                <Link component={RouterLink} to="/forget-password" >
                  Forgot Password?
                </Link>

                </div>
                <div className="action mb-4">
                  <button
                    disabled={isLoading}
                    type="submit"
                    className="shadow-main button-primary rounded-full h-12 w-81 shadow-md ml-2"
                  >
                    {isLoading ? "Loading..." : "Login"}
                  </button>
                </div>
              </form>
            </div>
          </section>
          <ResetPasswordNoAuth
            handleClose={handleCloseResetPassword}
            handleSubmits={() => {}}
            open={isResetPassword}
          />
        </main>
      </div>
  )
}

export default Login;
